import React, { useState, useEffect } from "react";
import { firestore } from "./firebase";
import {
  query,
  where,
  collection,
  getAggregateFromServer,
  sum,
} from "firebase/firestore";
import moment from "moment-timezone";

const beginningOfDayPDT = moment().tz("America/Los_Angeles").startOf("day");

const generateDateRanges = () => {
  const ranges = [];
  for (let i = 21; i >= 1; i--) {
    const start = beginningOfDayPDT.clone().subtract(i, "days").endOf("day");
    const end = beginningOfDayPDT
      .clone()
      .subtract(i - 14, "days")
      .endOf("day");
    if (end.isBefore(beginningOfDayPDT)) {
      ranges.push({ start, end });
    }
  }
  return ranges;
};

const OTDR = () => {
  const [stats, setStats] = useState([]);

  const fetchDeliveredCount = async (start, end) => {
    const q = query(
      collection(firestore, "shipments"),
      where("promisedDeliveryDate", ">=", start.toISOString()),
      where("promisedDeliveryDate", "<=", end.toISOString()),
      where("marketplace", "==", "Amazon"),
      where("trackingStatus", "==", "Delivered")
    );

    const snapshot = await getAggregateFromServer(q, {
      totalUnits: sum("numberOfUnits"),
    });
    return snapshot.data().totalUnits;
  };

  const fetchTotalLateCount = async (start, end) => {
    const q = query(
      collection(firestore, "shipments"),
      where("promisedDeliveryDate", ">=", start.toISOString()),
      where("promisedDeliveryDate", "<=", end.toISOString()),
      where("marketplace", "==", "Amazon"),
      where("deliveredLate", "==", true)
    );

    const snapshot = await getAggregateFromServer(q, {
      totalUnits: sum("numberOfUnits"),
    });
    return snapshot.data().totalUnits;
  };

  const fetchStats = async () => {
    const ranges = generateDateRanges();
    const promises = ranges.map(async ({ start, end }) => {
      const totalDeliveredCount = await fetchDeliveredCount(start, end);
      const totalLateCount = await fetchTotalLateCount(start, end);
      return {
        start,
        end,
        totalDeliveredCount,
        totalLateCount,
      };
    });

    const results = await Promise.all(promises);
    setStats(results);
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div>
      <h1>OTDR</h1>
      {stats.map(
        ({ start, end, totalDeliveredCount, totalLateCount }, index) => (
          <div key={index}>
            <p>
              Promised Delivery Date : {start.format("MMMM D")} -{" "}
              {end.format("MMMM D")}
            </p>
            <p>
              {start.toISOString()}
              {" <= Promised Delivery Date <= "}
              {end.toISOString()}
            </p>
            <p>
              {totalDeliveredCount - totalLateCount}/{totalDeliveredCount} ={" "}
              <b>
                {(
                  ((totalDeliveredCount - totalLateCount) /
                    totalDeliveredCount) *
                  100
                ).toFixed(2)}
                %
              </b>
            </p>
            <hr />
          </div>
        )
      )}
    </div>
  );
};

export default OTDR;
