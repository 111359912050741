import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import UserDropdown from "./UserDropdown";
import { Link } from "react-router-dom";
import { useTheme } from "./ThemeContext";
import withAuth from "./WithAuthHOC";

const NavBar = ({ auth }) => {
  const { user, isAdmin } = auth;
  const { theme } = useTheme();

  return (
    <Navbar bg={theme} variant={theme} expand="lg">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            src={`${process.env.PUBLIC_URL}/favicon.ico`}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Swift Ship Logo"
          />{" "}
          SwiftShip
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link as={Link} to="/scan-sheet">
              SCAN Sheet
            </Nav.Link>
            {isAdmin && (
              <Nav.Link as={Link} to="/settings">
                Settings
              </Nav.Link>
            )}
            <Nav.Link as={Link} to="/otdr">
              OTDR
            </Nav.Link>
            <Nav.Link as={Link} to="/premium-shipping-metrics">
              Premium Shipping
            </Nav.Link>
            <Nav.Link as={Link} to="/downloads">
              Downloads
            </Nav.Link>
            <div className="d-lg-none">
              <UserDropdown />
            </div>
          </Nav>
        </Navbar.Collapse>

        <div className="d-none d-lg-flex ms-auto">
          {" "}
          {/*This will only show on large screens */}
          <UserDropdown />
        </div>
      </Container>
    </Navbar>
  );
};

export default withAuth(NavBar);
