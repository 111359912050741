import React, { useEffect, useRef } from "react";

const PremiumShipping = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const adjustHeight = () => {
      if (containerRef.current) {
        const navBarHeight = document.querySelector("nav").offsetHeight;
        containerRef.current.style.height = `calc(100vh - ${navBarHeight}px)`;
      }
    };

    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    return () => {
      window.removeEventListener("resize", adjustHeight);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <iframe
        style={{ width: "100%", maxWidth: "1400px", height: "100%" }}
        src="https://lookerstudio.google.com/embed/reporting/e9754837-af0a-4d50-ae0b-5d0346d5ab37/page/mKWRE"
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </div>
  );
};

export default PremiumShipping;
{
  /* <iframe width="600" height="450" src="https://lookerstudio.google.com/embed/reporting/25967591-65aa-4119-8cb0-7595eaef5497/page/mKWRE" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe> */
}
