import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { omit } from "underscore";

const FilterContext = createContext();

export const useFilters = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const buildFiltersFromQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      id: params.get("id") || "",
      marketplace: params.get("marketplace") || "",
      carrier: params.get("carrier") || "",
      userId: params.get("userId") || "",
      shipDateStart: params.get("shipDateStart") || "",
      shipDateEnd: params.get("shipDateEnd") || "",
      overcharged: params.get("overcharged") ? true : false,
      saturdayDelivery: params.get("saturdayDelivery") || "",
      requestedShipping: params.get("requestedShipping") || "",
      service: params.get("service") || "",
      shippingLabelApi: params.get("shippingLabelApi") || "",
    };
  };

  const [filters, setFilters] = useState(buildFiltersFromQueryParams());

  // Update URL when filters change
  useEffect(() => {
    const cleanedFilters = omit(filters, (value) => !value);
    const queryParams = new URLSearchParams(cleanedFilters).toString();
    if (queryParams != location.search.substring(1)) {
      //avoid infinite useEffect loop
      navigate({ pathname: location.pathname, search: queryParams });
    }
  }, [filters]);

  // Update filters when URL changes
  useEffect(() => {
    setFilters(buildFiltersFromQueryParams());
  }, [location.search]);

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};
