import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { doc, updateDoc, getFirestore, getDoc } from "firebase/firestore";
import { useToast } from "./ToastContext";
import "./Settings.css";

const Settings = () => {
  const [useEVS, setUseEVS] = useState(false);
  const [useVeeqo, setUseVeeqo] = useState(false);
  const [useVeeqoUSPS, setUseVeeqoUSPS] = useState(false);
  const { showToast, showSuccessToast } = useToast();

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsRef = doc(getFirestore(), "appSettings", "settings");
      const docSnap = await getDoc(settingsRef);
      setUseEVS(docSnap.data().useEVS);
      setUseVeeqo(docSnap.data().useVeeqo);
      setUseVeeqoUSPS(docSnap.data().useVeeqoUSPS);
    };
    fetchSettings();
  }, []);

  const handleSave = async () => {
    try {
      const settingsRef = doc(getFirestore(), "appSettings", "settings");
      await updateDoc(settingsRef, { useEVS, useVeeqo, useVeeqoUSPS });
      showSuccessToast("Success", "Settings saved successfully!");
    } catch (error) {
      showToast("Error", "Error saving settings.");
    }
  };

  return (
    <div className="settings-container">
      <div className="usps-section" style={{ paddingBottom: "20px" }}>
        <Form className="settings-form">
          <Form.Group>
            <h4>USPS</h4>
            <Form.Check
              type="switch"
              label="EVS Account Enabled"
              checked={useEVS}
              onChange={(e) => setUseEVS(e.target.checked)}
            />
            <Form.Check
              type="switch"
              label="Veeqo Enabled"
              checked={useVeeqoUSPS}
              onChange={(e) => setUseVeeqoUSPS(e.target.checked)}
            />
            <Form.Text className="text-muted">
              When enabled, Veeqo will be prioritized over EVS and will remain
              active until 5 PM.
            </Form.Text>
          </Form.Group>
        </Form>
      </div>
      <div className="fedex-section" style={{ paddingBottom: "20px" }}>
        <Form className="settings-form">
          <Form.Group>
            <h4>FedEx</h4>
            <Form.Check
              type="switch"
              label="Veeqo Enabled"
              checked={useVeeqo}
              onChange={(e) => setUseVeeqo(e.target.checked)}
            />
            <Form.Text className="text-muted">
              When enabled, Veeqo will remain active until 5 PM.
            </Form.Text>
          </Form.Group>
        </Form>
      </div>
      <div className="form-actions">
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Settings;
