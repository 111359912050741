import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useUsers } from "./UsersContext";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFilters } from "./ShipmentsFilterContext";

export const ShipmentsFilters = () => {
  const users = useUsers();
  const { filters, setFilters } = useFilters();

  const handleIDChange = (event) => {
    setFilters({ ...filters, id: event.target.value });
  };

  const handleMarketplaceChange = (event) => {
    setFilters({ ...filters, marketplace: event.target.value });
  };

  const handleApiChange = (event) => {
    setFilters({ ...filters, shippingLabelApi: event.target.value });
  };

  const handleCarrierChange = (event) => {
    setFilters({ ...filters, carrier: event.target.value });
  };

  const handleServiceChange = (event) => {
    setFilters({ ...filters, service: event.target.value });
  };

  const handleRequestedShippingChange = (event) => {
    setFilters({ ...filters, requestedShipping: event.target.value });
  };

  const handleSDRChange = (event) => {
    setFilters({ ...filters, saturdayDelivery: event.target.value });
  };

  const handleUserChange = (event) => {
    setFilters({ ...filters, userId: event.target.value });
  };

  const handleStartDateChange = (date) => {
    const dateString = date ? date.format("YYYY-MM-DD") : "";

    const shipDateStart = dateString;
    if (filters.shipDateEnd && date.isAfter(filters.shipDateEnd)) {
      setFilters({ ...filters, shipDateEnd: dateString });
    }

    setFilters({ ...filters, shipDateStart: shipDateStart });
  };

  const handleEndDateChange = (date) => {
    const dateString = date ? date.format("YYYY-MM-DD") : "";
    setFilters({ ...filters, shipDateEnd: dateString });
  };

  const handleOverChargedChange = (event) => {
    if (event.target.checked) {
      setFilters({ ...filters, overcharged: true });
    } else {
      setFilters({ ...filters, overcharged: false });
    }
  };

  const onClear = () => {
    setFilters({
      id: "",
      marketplace: "",
      carrier: "",
      userId: "",
      shipDateStart: "",
      shipDateEnd: "",
      overcharged: false,
      saturdayDelivery: "",
      shippingLabelApi: "",
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <FilterListIcon style={{ marginRight: 8 }} />
        Filters
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              type="text"
              placeholder="Order ID or Tracking Number"
              value={filters.id}
              onChange={handleIDChange}
              style={{ width: 250 }}
            />
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 140, marginBottom: 0 }}>
              <InputLabel id="marketplace-select-label">Marketplace</InputLabel>
              <Select
                labelId="marketplace-select-label"
                id="marketplace-select"
                value={filters.marketplace}
                label="Marketplace"
                onChange={handleMarketplaceChange}
              >
                <MenuItem value={"Amazon"}>Amazon</MenuItem>
                <MenuItem value={"eBay"}>eBay</MenuItem>
                <MenuItem value={"Walmart"}>Walmart</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 140, marginBottom: 0 }}>
              <InputLabel id="carrier-select-label">Carrier</InputLabel>
              <Select
                labelId="carrier-select-label"
                id="carrier-select"
                value={filters.carrier}
                label="Carrier"
                onChange={handleCarrierChange}
              >
                <MenuItem value={"USPS"}>USPS</MenuItem>
                <MenuItem value={"UPS"}>UPS</MenuItem>
                <MenuItem value={"FEDEX"}>FEDEX</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 140, marginBottom: 0 }}>
              <InputLabel id="service-select-label">Service</InputLabel>
              <Select
                labelId="service-select-label"
                id="service-select"
                value={filters.service}
                label="Service"
                onChange={handleServiceChange}
              >
                <MenuItem value={"GROUND_ADVANTAGE"}>Ground Advantage</MenuItem>
                <MenuItem value={"FEDEX_2_DAY"}>2 Day</MenuItem>
                <MenuItem value={"STANDARD_OVERNIGHT"}>
                  Standard Overnight
                </MenuItem>
                <MenuItem value={"PRIORITY_OVERNIGHT"}>
                  Priority Overnight
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 140, marginBottom: 0 }}>
              <InputLabel id="requested-shipping-select-label">
                Requested Shipping
              </InputLabel>
              <Select
                labelId="requested-shipping-select-label"
                id="requested-shipping-select"
                value={filters.requestedShipping}
                label="Requested Shipping"
                onChange={handleRequestedShippingChange}
              >
                <MenuItem value={"Express"}>Express</MenuItem>
                <MenuItem value={"Priority"}>Priority</MenuItem>
                <MenuItem value={"Standard"}>Standard</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 80, marginBottom: 0 }}>
              <InputLabel id="sdr-select-label">SDR</InputLabel>
              <Select
                labelId="sdr-select-label"
                id="sdr-select"
                value={filters.saturdayDelivery}
                label="SDR"
                onChange={handleSDRChange}
              >
                <MenuItem value={"yes"}>Yes</MenuItem>
                <MenuItem value={"no"}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 140, marginBottom: 0 }}>
              <InputLabel>User</InputLabel>
              <Select
                value={filters.userId}
                label="User"
                onChange={handleUserChange}
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-us"
            >
              <DatePicker
                label="Ship Date Start"
                value={
                  filters.shipDateStart ? moment(filters.shipDateStart) : null
                }
                onChange={handleStartDateChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-us"
            >
              <DatePicker
                label="Ship Date End"
                value={filters.shipDateEnd ? moment(filters.shipDateEnd) : null}
                onChange={handleEndDateChange}
                minDate={
                  filters.shipDateStart ? moment(filters.shipDateStart) : null
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 140, marginBottom: 0 }}>
              <InputLabel id="api-select-label">Api</InputLabel>
              <Select
                labelId="api-select-label"
                id="api-select"
                value={filters.shippingLabelApi}
                label="Api"
                onChange={handleApiChange}
              >
                <MenuItem value={"evs"}>EVS</MenuItem>
                <MenuItem value={"veeqo"}>Veeqo</MenuItem>
                <MenuItem value={"shipstation"}>Shipstation</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControlLabel
              value="top"
              control={<Switch color="primary" checked={filters.overcharged} />}
              label="Overcharged"
              labelPlacement="top"
              onChange={handleOverChargedChange}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={onClear}
              color="secondary"
              variant="outlined"
              startIcon={<ClearIcon />}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ShipmentsFilters;
